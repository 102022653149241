<template>
  <a-select
    :value="value"
    mode="multiple"
    :showSearch="true"
    :placeholder="placeholder"
    :allowClear="true"
    style="width: 100%"
    @change="onChange"
  >
    <a-select-option v-for="item in dataItems" :key="item.id" :value="item.id" :item="item">{{ item.name }}</a-select-option>
  </a-select>
</template>

<script>
import { suppliersOption } from "@/api/option";

export default {
  props: ["value", "disabled", "placeholder"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      dataItems: [],
    };
  },
  methods: {
    onChange(value) {
      this.$emit("change", value);
    },
  },
  mounted() {
    suppliersOption({ page_size: 999999 }).then((data) => (this.dataItems = data.results));
  },
};
</script>

<style scoped></style>
